<template>
    <div class="reserveren">

        <navigation></navigation>

        <div id=container>
            <div class="slider-wrap showcase om-section block-0" id=slider-9ae29c08a50641602b510d16b5084bcc>
                <ul class="slider arrows arrows-hidden dots dots-default" data-slider-speed=5000 data-height=fixed-ratio data-att=35>
                    <li class>
                        <figure>
                            <img alt="Brasserie Den Anker" src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class=overlay>
                        <h1>Contact</h1>
                        <p>
                            <router-link class=button to="/reserveren">Reserveren</router-link>
                        </p>
                    </section>
                </div>
            </div>

            <div class="content-wrap content-form om-section block-1" id=content-form-9ae29c08a50641602b510d16b5084bcc><a class="anchor scrollto-anchor" id=scrollto-anchor-1></a>
                <section class=content>
                    <h1 style="margin-bottom: 30px" class="entry-title"><span>Contact</span></h1>
                    <div class="clearfix page-form-wrap form-position-right">
                        <div class=item-page-content>
                            <h2>Brasserie Den Anker</h2>
                            <p style="margin-bottom: 0">BARCODA CVOA</p>
                            <p style="margin-top: 0" class=vat><span class=prefix>BTW </span>
                                <span class=value>BE0843.524.767</span>
                            </p>
                            <div class=vcard>
                                <p class=adr><span class=street-address>Polderstraat 1A</span><br><span class=postal-code>B-2260</span> <span class=locality>Westerlo</span><br><span class=country-name>Belgi&euml;</span></p>
                                <p class=tel><span class=prefix>Telefoon</span><a href=tel:+3214728352><span
                                        class=value>+32 14 72 83 52</span></a><span class=type>work</span><span class=type>pref</span></p>

                                <p class=tel style="margin-bottom: 0;font-weight:bold">Kennith Van den Bergh</p>
                                <p class=tel style="margin-bottom: 0;margin-top: 0">Zaakvoerder</p>
                                <p class=email-wrap><a class=email href="kennith@brasseriedenanker.be"><span class=value>kennith@brasseriedenanker.be</span></a>
                                    <span class=type>pref</span>
                                </p>

                                <p class=tel style="margin-bottom: 0;font-weight:bold">Kristof Van Reusel</p>
                                <p class=tel style="margin-bottom: 0;margin-top: 0">Assistent Manager & Keukenverantwoordelijke</p>
                                <p class=email-wrap><a class=email href="kristof@brasseriedenanker.be"><span class=value>kristof@brasseriedenanker.be</span></a>
                                    <span class=type>pref</span>
                                </p>

                            </div>
                            <!--                            <p style="text-align: center;"><a class=button href=https://www.brasserie500.be/wordpress/wp-content/uploads/2018/05/Feestenboekje-Brasserie-500.pdf>Download feestenboekje</a></p>-->
                        </div>
                        <div class=item-form-content>
                            <form accept-charset=UTF-8 class="form clearfix" id=om-form-contact>
                                <div class=form-wrap>
                                    <fieldset>
                                        <p class=small>
                                            <label for=om-field-input_default_firstname>Voornaam
                                                <abbr class=obligatory title=Verplicht>*</abbr>
                                            </label>
                                            <input id=om-field-input_default_firstname v-model="data.firstname" placeholder="voornaam" required=required tabindex=1 type=text value>
                                        </p>
                                        <p class=small>
                                            <label for=om-field-input_default_name>Achternaam <abbr class=obligatory title=Verplicht>*</abbr></label>
                                            <input id=om-field-input_default_name v-model="data.name" placeholder="naam" required=required tabindex=2 type=text value></p>
                                        <p class=small>
                                            <label for=om-field-input_tel_phone>Telefoon / GSM</label>
                                            <input id=om-field-input_tel_phone v-model="data.tel" placeholder="telefoonnummer" tabindex=6 type=text value></p>
                                        <p class=small>
                                            <label for=om-field-input_email_email>E-mailadres <abbr class=obligatory title=Verplicht>*</abbr></label>
                                            <input id=om-field-input_email_email v-model="data.email" placeholder="e-mailadres" type="email" required=required tabindex=8 value></p>
                                        <p class=large>
                                            <label for=om-field-textarea_default_message>Vragen of opmerkingen</label>
                                            <textarea id=om-field-textarea_default_message v-model="data.text" rows="4" tabindex=9></textarea>
                                        </p>
                                    </fieldset>
                                    <div class=center>
                                        <div id="msg" v-if="msg" style="margin-bottom: 20px">
                                            <p>{{ msg }}</p>
                                        </div>
                                        <v-btn @click.prevent="submitForm" :loading="loading" class=button id=om-field-submit depressed large>Verzenden</v-btn>
<!--                                        <button @click.prevent="recaptcha">Execute recaptcha</button>-->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

        </div>

        <app-footer></app-footer>

        <svg id=icon-sprite-social>
            <defs>
                <symbol id=icon-facebook>
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>

    </div>
</template>

<script>
    import AppFooter  from '../components/Footer'
    import Navigation from '../components/Navigation'

    export default {
        name: 'Contact',
        components: {Navigation, AppFooter},
        data() {
            return {
                msg: '',
                loading: false,
                data: {
                    name: '',
                    firstname: '',
                    tel: '',
                    email: '',
                    text: ''
                }
            }
        },
        mounted() {
            $(window).scrollTop(0)
        },
        methods: {
            recaptcha() {
                console.log('recaptcha clicked')
                this.$recaptchaLoaded().then(() => {
                    console.log('recaptcha loaded')
                    this.$recaptcha('login').then((token) => {
                        console.log(token) // Will print the token
                        if (token) {
                            this.submitForm()
                        } else {
                            console.log('token false')
                            return
                        }
                    }).catch( err =>{
                        console.log(err)
                        return
                    })
                })
            },
            // recaptcha() {
            //     // (optional) Wait until recaptcha has been loaded.
            //     this.$recaptchaLoaded()
            //     // Execute reCAPTCHA with action "login".
            //     const token = this.$recaptcha('login')
            //     console.log('token: ' + token)
            //
            // },
            submitForm() {
                this.loading = true
                this.$axios.post('api/submitForm', {
                    data: this.data,
                }).then(response => {
                    this.msg = response.data
                    this.data.name = ''
                    this.data.firstname = ''
                    this.data.tel = ''
                    this.data.email = ''
                    this.data.text = ''
                    this.loading = false
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    a {
        color: #a09174;
    }
    .slider-wrap figure {
        min-height: 65vh;
    }

    #msg {
        transition: .3s
    }

    #container .slider-wrap figure img {
        height: 65vh !important;
    }

    input[type="submit"]:hover {
        background-color: #1d1d1b;
        border: none;
        color: #fff !important;
    }

    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #a09174 !important;
        font-family: "Muli", sans-serif !important;
        font-size: 18px !important;
        color: #fff
    }

    input:not(.button):not(.ghost):not([type=radio]):not([type=checkbox]):focus {
        border-color: #a09174;
    }

    input:not(.button):not(.ghost):not([type=radio]):not([type=checkbox]) {
        border: 1px solid #000;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        height: 40px;
        background-color: #ebeef1;
        color: #2a343e;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
        -webkit-appearance: none;
        border-radius: 0;
    }

    input:not(.button):not(.ghost):not([type=radio]):not([type=checkbox]):focus {
        border-color: #a09174;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    input[type="button"], button, input[type="submit"], .buttons {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        height: 44px;
        font-size: 18px;
        text-transform: uppercase;
        border-radius: 0;
        -webkit-border-radius: 0;
        letter-spacing: 2px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    textarea {
        height: auto;
        background-color: #ebeef1 !important;
    }

    textarea:focus {
        border-color: #a09174;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
</style>
